<template>
    <div class="aipage">
        <div class="banner-img background" :style="{ 'backgroundImage' : `url(${require('@/assets/images/ai/msxBanner.png')})`}">
            <div class="banner">
                <div class="banner-title background" :style="{ 'backgroundImage' : `url(${require('@/assets/images/ai/msxTitle.png')})`}"></div>
                <div class="banner-smallTitle">ModelSpaceX 为用户提供海量数据采集、数据标注、数据治理、模型开发、模型训练、模型评估、模型部署等AI建模流程所需的一站式平台支撑；
                ModelSpaceX 能有效降低企业进入人工智能领域的门槛，企业无需搭建AI工程师团队，在零编码的条件下，最快5分钟即可获得定制化的AI模型，并一键部署至各种云端或边缘端设备。</div>
            </div>
        </div>
        <div class="aipage_wrap" style="padding-bottom: 0.6rem;">
            <div class="aipage_content">
                <p class="titleBlue">平台亮点</p>
                <div class="flex-wrap">
                    <div class="aipage-cardRow" v-for="item in highlights" :key="item.index">
                        <div class="aipage-cardRow-icon background" :style="{ 'backgroundImage' : `url(${ item.icon })`}"></div>
                        <div class="aipage-cardRow-main">
                            <div class="aipage-cardRow-title">{{ item.title }}</div>
                            <div class="aipage-cardRow-content">{{ item.content }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="aipage_wrap background" :style="{ 'backgroundImage' : `url(${require('@/assets/images/ai/bjBig.png')})`, 'height':'5.2rem' }">
            <div class="aipage_content">
                <p class="whiteBlue">平台功能</p>
                <div class="flex-center">
                    <div class="plateInfo-left">
                        <div class="column-tab" :class="{ 'actived-tab': index == clickIndex }" v-for="(item,index) in plateFormFunction" :key="index" @click="changeTab(item,index)">{{ item.title }}</div>
                    </div>
                    <div class="plateInfo-right">
                        <div v-if="plateFormInfo.type && plateFormInfo.type == 2">
                            <div class="plateInfo-bgLong background" :style="{ 'backgroundImage' : `url(${require('@/assets/images/ai/rockBg.png')})`}"></div>
                            <div class="plateInfo-step">
                                <div class="plateInfo-step-item" v-for="item in plateFormInfo.infos" :key="item.index">
                                    <div class="plateInfo-step-title">{{ item.title }}</div>
                                    <div class="plateInfo-step-label">{{ item.label }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-center">
                            <div v-for="item in plateFormInfo.pics" :key="item.index" class="background" :style="{ 'backgroundImage' : `url(${ item })`, 'width':'4.4rem', 'height':'2.4rem', 'background-size':'cover','margin':'0 0.3rem', 'border-radius':'0.06rem' }">
                            </div>
                        </div>
                        <div v-if="!plateFormInfo.type" style="position: relative;top:0.4rem;left: 0.3rem;">
                            <div v-for="item in plateFormInfo.infos" :key="item.index">
                                <span class="dot-w"></span>
                                <span style="display:inline-block;color: #fff;font-size: 0.12rem;margin-left: 0.1rem;height: 0.24rem;line-height: 0.24rem;">{{ item.label }}</span>
                            </div>
                        </div>
                        <div v-if="plateFormInfo.type && plateFormInfo.type == 3" style="display: flex;justify-content: space-between;">
                            <div class="box-left flex-center" style="flex-direction: column;">
                                <div class="box-blue-title">{{ plateFormInfo.infos[0].title }}</div>
                                <div class="box-middle-label">{{ plateFormInfo.infos[0].label }}</div>
                                <div>
                                    <img :src="plateFormInfo.infos[0].pic" alt="">
                                </div>
                            </div>
                            <div class="box-right flex-center" style="flex-direction: column;">
                                <div class="box-blue-title" style="background-color: #009BE3;">{{ plateFormInfo.infos[1].title }}</div>
                                <div class="box-middle-label">{{ plateFormInfo.infos[0].label }}</div>
                                <div >
                                    <img :src="plateFormInfo.infos[1].pics[0]" alt="" style="margin-right: 0.42rem;">
                                    <img :src="plateFormInfo.infos[1].pics[1]" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="aipage_wrap" style="padding-bottom: 0.8rem;">
            <div class="aipage_content">
                <p class="titleBlue">客户收益</p>
                <div class="flex-center">
                    <div class="servicewrap background" v-for="item in serviceObj" :key="item.index" :style="{ 'backgroundImage' : `url(${ item.bgurl })`}">
                        <div style="text-align: center;height: 0.5rem;font-size: 0.18rem;">{{ item.title }}</div>
                        <div style="font-size: 0.14rem;letter-spacing: 0.02rem;">{{ item.label }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="aipage_wrap" style="padding-bottom: 0.8rem;">
            <div class="aipage_content">
                <p class="titleBlue">算力租用</p>
                <div class="flex-center">
                    <div class="computationalWrap" v-for="item in computationals" :key="item.index">
                        <div :style="{ 'backgroundImage' : `url(${ item.url })`}" class="computational-title background">
                            <span>{{ item.title }}</span>
                        </div>
                        <div class="computational-content">
                            <div class="flex-wrap">
                                <div v-for="e in item.info" :key="e.index" style="width: 50%;margin-bottom: 0.18rem;">
                                    <div class="content-name">{{ e.name }}</div>
                                    <div class="content-label">{{ e.label }}</div>
                                </div>
                            </div>
                            <el-divider></el-divider>
                            <div class="flex-between" style="line-height: 0.3rem;">
                                <div>单卡低至：<span style="font-size: 0.2rem;font-weight: 600;color: #0061e3;">{{item.price }}</span> 元/日</div> 
                                <div>
                                    <el-button>立即购买</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  <script>
  /* eslint-disable */
  export default {
    data() {
      return {
        clickIndex:0,
        plateFormInfo:{},
        highlights:[
            { title:'一站式', content:'模型开发和部署的全流程一站式支撑', icon:require('@/assets/images/ai/highlightIcon1.png')},
            { title:'零门槛', content:'最快5分钟内获得一个自定义的高精度模型', icon:require('@/assets/images/ai/highlightIcon2.png')},
            { title:'高性能', content:'集成自研高性能算法，适配国产寒武纪芯片，提供标准、高效的计算环境', icon:require('@/assets/images/ai/highlightIcon3.png')},
            { title:'易扩展', content:'基于微服务研发，可自由扩展组件', icon:require('@/assets/images/ai/highlightIcon4.png')},
        ],
        plateFormFunction:[
            { 
                title:'数据管理',
                infos:[
                    { label:'支持从文件系统数据源、关系型数据库数据源、大数据体系数据源、接口数据源中接入多种格式的数据。' }, 
                    { label:'支持平台预置数据集：包括开源库（人脸/人体/日常物体/车等），行业库（安全帽/工服/电力金具等），电力专业库（输电破损/电表/巡检等）。' },
                    { label:'支持数据集的在线预览、修改、转存和下载等操作。' },
                    { label:'支持数据集的权限管理以及协作共享。' },
                ],
                pics:[require('@/assets/images/ai/slice1-1.png'),require('@/assets/images/ai/slice1-2.png')]
            },
            { 
                title:'模型训练',
                infos:[
                    { label:'支持平台硬件资源的统一调度和分配，多租户之间资源隔离，单租户内部资源争抢，从而提高资源使用率；' },
                    { label:'支持各维度资源使用率的统计和查看；' },
                    { label:'支持基于工作流引擎的训练任务的灵活编排；' },
                    { label:'支持模型训练完成后的在线评估；' },
                ],
                pics:[require('@/assets/images/ai/slice2-1.png'),require('@/assets/images/ai/slice2-2.png')]
            },
            { 
                title:'模型仓库',
                infos:[
                    { title:'模型权限管理', label:'支持对既有算法进行管理，包括个人算法、开源预训练库，企业内部共享算法等' },
                    { title:'模型版本迭代', label:'支持针对模型的不同版本进行管理，支持模型版本的迭代更新' },
                    { title:'模型版本发布', label:'模型版本创建成功后用户可以进行模型版本的发布，支持CPU/内存/GPU等资源的按需配置' },
                    { title:'模型版本验证', label:'模型版本发布成功后，支持用户对已发布的服务进行测试、验证和下线等操作' },
                ],
                pics:[require('@/assets/images/ai/slice3-1.png')],
                type:2,
            },
            { 
                title:'模型发布',
                infos:[
                    { title:'REST API', label:'支持将模型发布为REST API对外提供推理服务', pic:require('@/assets/images/ai/slice4-1.png') },
                    { title:'模型的发布与验证', label:'支持在模型发布时指定推理服务运行的环境和资源（GPU/CPU/内存）；支持用户进行推理服务的在线验证；', pics:[require('@/assets/images/ai/slice4-2.png'),require('@/assets/images/ai/slice4-3.png')] },
                ],
                type:3,
            },
        ],
        serviceObj:[
            { title:'软件公司', label:'有独立能力实施AI导入的各企业，如软件研发公司，软件服务公司', bgurl:require('@/assets/images/ai/service1.png') },
            { title:'软件服务承包商', label:'基于产品进行定制开发或提供运营服务，如软件服务承包商', bgurl:require('@/assets/images/ai/service2.png') },
            { title:'传统行业/企业', label:'希望找服务商协助导入AI，如企事业单位、各传统行业企业', bgurl:require('@/assets/images/ai/service3.png') },
            { title:'学习/培训机构', label:'基于产品进行教学或科研实践，如学校或培训机构', bgurl:require('@/assets/images/ai/service4.png') },
        ],
        computationals:[
            {   
                url:require('@/assets/images/ai/MLU270.png'),
                title:'寒武纪 MLU270', 
                price:'9.98',
                info:[
                    { name:'INT8', label:'128TOPS' },
                    { name:'INT16', label:'64TOPS' },
                    { name:'内存', label:'16GB DDR4' },
                ]
            },
            {   
                url:require('@/assets/images/ai/MLU290.png'),
                title:'寒武纪 MLU290', 
                price:'19.98',
                info:[
                    { name:'INT8', label:'512TOPS' },
                    { name:'INT16', label:'256TOPS' },
                    { name:'CINT32', label:'64TOPS' },
                    { name:'内存', label:'32GB DDR4' },
                ]
            },
            {   
                url:require('@/assets/images/ai/MLU370.png'),
                title:'寒武纪 MLU370', 
                price:'29.98',
                info:[
                    { name:'INT8', label:'256TOPS' },
                    { name:'INT16', label:'128TOPS' },
                    { name:'FP32', label:'24TOPS' },
                    { name:'内存', label:'48GB LPDDR5' },
                ]
            }
        ]
      }
    },
   
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.plateFormInfo = this.plateFormFunction[0]
    },
    methods: {
        changeTab(e,i){
            this.clickIndex = i
            this.plateFormInfo = e
        }
    },
  }
    </script>
<style lang="scss" scoped>
.banner {
    left: 20%;
    top:2rem;
    &-title{
        width: 4.7rem;
        height: 1.4rem;
        background-size: contain;
    }
    &-smallTitle{
        color: #fff;
        width: 6.8rem;
        letter-spacing: 2px;
        line-height: 0.24rem;
        font-size: 0.14rem;
    }
}
.background{
    background-position: center;
    background-repeat: no-repeat;
}
.banner-img{
    width: 100%;
    height: 4rem;
    position: relative;
    background-size: cover;
}
.titleBlue{
    font-size: 0.3rem;
    color: #0061E3;
    font-weight: 600;
    letter-spacing: 0.08rem;
    text-align: center;
    height: 0.6rem;
    margin: 0.2rem 0;
}
.whiteBlue{
    width: 100%;
    font-size: 0.28rem;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.08rem;
    text-align: center;
    height: 0.6rem;
}
.aipage-cardRow{
    background: linear-gradient(90deg, #FFFFFF, #EBF3FF);
    width: 5rem;
    height: 1.1rem;
    padding: 0.1rem 0.4rem;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 8px 1px #e8e8e8;
    margin-bottom: 0.4rem;
    border-radius: 0.04rem;
    &-icon{
        width: 1.2rem;
        height: 1.3rem;
        background-size: contain;
        position: relative;
        bottom: 0.3rem;
    }
    &-main{
        width: calc(100% - 1.4rem);
        height: 1.1rem;

    }
    &-title{
        font-size: 0.22rem;
        color: #333;
        font-weight: 600;
        width: 100%;
        height: 0.4rem;
        line-height: 0.4rem;
        letter-spacing: 0.04rem;
    }
    &-content{
        font-size: 0.14rem;
        color: #a8a8a8;
        margin-top: 0.1rem;
    }
}
.aipage-cardRow:nth-of-type(odd){
    margin-right: 0.2rem;
}
.aipage-cardRow:nth-of-type(even){
    margin-left: 0.2rem;
}
.column-tab{
    font-size: 0.18rem;
    color: #fff;
    font-weight: 600;
    width: 1.6rem;
    height: 0.52rem;
    text-align: center;
    line-height: 0.52rem;
    border-radius: 0.04rem;
    cursor: pointer;
    margin: 0.2rem 0;
    letter-spacing: 0.02rem;
}
.column-tab:hover{
    color:#0061E3;
}
.actived-tab{
    color:#0061E3;
    background-color: #fff;
}
.plateInfo-left{
    width: 2rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.plateInfo-right{
    width: calc(100% - 2.2rem);
    margin-left: 0.2rem;
}
.plateInfo-bgLong{
    width: 10rem;
    height: 0.36rem;
    background-size: contain;
}
.plateInfo-step{
    width: 10rem;
    height: 1rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    bottom: 0.1rem;
    &-item{
        width: 2.2rem;
        color: #fff;
        margin-left: 0.1rem;
    }
    &-title{
        font-size: 0.16rem;
        font-weight: 600;
        height: 0.24rem;
        position: relative;
        bottom: 0.18rem;
    }
    &-label{
        font-size: 0.14rem;
        line-height: 0.22rem;
        letter-spacing: 0.01rem;
    }
}
.box-left{
    width: 3rem;
}
.box-right{
    width: calc(100% - 4rem);
}
.box-blue-title{
    width: 100%;
    height: 0.32rem;
    line-height: 0.32rem;
    background-color: #0061E3;
    color: #fff;
    font-size: 0.18rem;
    font-weight: 600;
    text-align: center;
    border-radius: 0.04rem;
}
.box-middle-label{
    height: 0.6rem;
    line-height: 0.6rem;
    color: #fff;
}
.servicewrap{
    width: 1.8rem;
    height:2.7rem;
    background-size: cover;
    color: #fff;
    padding: 0.3rem 0.6rem;  
}
.computationalWrap{
    width: 3.8rem;
    height: 3.4rem;
    cursor: pointer;
}
.computational-title{
    background-size: cover;
    width: 3.8rem;
    height: 1.5rem;
    position: relative;
    span{
        font-size: 0.2rem;
        color: #fff;
        position: absolute;
        right: 0.2rem;
        bottom: 0.2rem;
        letter-spacing: 0.02rem;
        font-weight: 600;
    }
}
.computational-content{
    width: 3.4rem;
    height: 2rem;
    padding: 0.2rem;
    background-color: #fff;
}
.computationalWrap:nth-of-type(2){
    margin: 0 0.3rem;
}
.computationalWrap:hover{
    .computational-content{
        box-shadow: 0px 0px 10px 5px #ececec;
    }
}
.content-label{
    height: 0.16rem;
    line-height: 0.16rem;
    text-indent: 0.06rem;
    border-left: 2px solid #0061E3;
    font-size: 0.16rem;
    font-weight: 600;
    color: #333;
    margin: 0.08rem 0;
}
.content-name{
    font-size: 0.14rem;
    color: #a8a8a8;
    font-weight: 600;
}
</style>
<style lang="scss">
.aipage{
    .el-button--small{
        padding:0.1rem 0.4rem;
        border-color: #0061e3;
        color: #0061e3;
        font-weight: 600;
        border-radius: 0;
    }
    .el-button--small:hover{
        background-color: #0061e3;
        color: #fff;
    }
}
.aipage_wrap{
    width: calc(100% - 0.48rem);
    display: flex;
    justify-content: center;
    padding: 0.24rem;
    background-color: #f5f5f5;
}
.aipage_content{
    width: 12rem;
}
.flex-center{
    display: flex;
    justify-content: center;
}
.flex-wrap{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.flex-between{
    display: flex;
    justify-content: space-between;
}
.dot-w{
    display: inline-block;
    width: 0.08rem;
    height: 0.08rem;
    background-color: #fff;
    border-radius: 50%;
}
.el-divider--horizontal{
    margin: 0.16rem 0;
}

</style>
 